export default class ApiEndPoint {
  static Otp = '/otp';

  static Send = '/send';

  static Verify = '/verify';

  static Auth = '/auth';

  static User = '/user';

  static Logout = '/logout';

  static Crmlite = '/crmlite';

  static Filters = '/filter';

  static All = '/all';

  static Order = '/order';

  static Search = '/search';

  static Report = '/report';

  static Upload = '/upload';

  static Bulk = '/bulk';

  static Sales = '/sales';

  static Brand = '/brand';
}
