import axios from 'axios';
import {
  apiErrorResponse,
  DispatchNotification,
  deleteAllCookies,
} from '../helpers/general';
/* eslint-disable */
const baseUrl = window.location.origin + '/api/';
// const baseUrl = process.env.REACT_APP_API_ENDPOINT;
/* eslint-disable */
const client = axios.create({
  baseURL: baseUrl,
  withCredentials: true,
  headers: {},
  timeout: 50000,
});

const request = ({ method, url, headers = {}, data = {} }) => {
  const onSuccess = (response) => response;
  const onError = (error) => {
    const errorMessage = apiErrorResponse(error?.response ?? {});

    /* application should get logged out and clear the cookies on 401 and 403 error response*/

    const routeTo = 'login';
    if (error.response.status === 403 || error.response.status === 401) {
      deleteAllCookies();
      window.location = `${window.location.origin}/${routeTo}`;
    }

    DispatchNotification(errorMessage ?? 'Something Went Wrong');
    return Promise.reject(error);
  };

  const payload = {
    method,
    url,
    headers: {
      ...headers,
    },
    data,
  };

  return client(payload).then(onSuccess).catch(onError);
};

const Api = {
  get: (url, params, headers) => {
    return request({
      method: 'GET',
      url,
      headers,
      params,
    });
  },
  post: (url, data, headers, responseType) => {
    return request({
      method: 'POST',
      url,
      headers,
      data,
      responseType,
    });
  },
  put: (url, data, headers) => {
    return request({
      method: 'PUT',
      url,
      headers,
      data,
    });
  },
  delete: (url, headers) => {
    return request({
      method: 'DELETE',
      url,
      headers,
    });
  },
};

export const AuthApi = axios.create({
  baseURL: baseUrl,
  withCredentials: true,
  headers: {
    Authorization: `Basic ${process.env.REACT_APP_BASIC_TOKEN}`,
  },
});

export const ReportApi = axios.create({
  baseURL: baseUrl,
  withCredentials: true,
});

ReportApi.interceptors.response.use(
  (res) => res,
  (error) => {
    const errorMessage = apiErrorResponse(error?.response ?? {});
    DispatchNotification(errorMessage ?? 'Something Went Wrong');
    return Promise.reject(error);
  },
);

AuthApi.interceptors.response.use(
  (res) => res,
  (error) => {
    if (window.location.href.includes('/login')) return Promise.reject(error);
    const errorMessage = apiErrorResponse(error?.response ?? {});
    DispatchNotification(errorMessage ?? 'Something Went Wrong');
    return Promise.reject(error);
  },
);

export default Api;
