import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ButtonBase, Popover } from '@mui/material';
import PropTypes from 'prop-types';
import { logout } from '../../../api/auth';
import Avatar from '../../common/Avatar';
import HeaderComponent from './header.style';
import {
  DEFAULT_LANDING_ROUTE,
  DEFAULT_LANDING_ROUTE_ADMIN,
  DEFAULT_LANDING_ROUTE_SALES,
} from '../../../helpers/constant';
import CustomLink from '../../common/CustomLink';
import Typography from '../../common/Typography/Typography';

export default function Header({ logo, profilePicture, isPublicRoute }) {
  const { Wrapper, Container, Profile, MenuItem } = HeaderComponent;
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  // useEffect(() => {
  //   if (window.location.href.includes('/report')) setReportRoute(true);
  // }, []);

  const handleLogout = () => {
    setAnchorEl(null);
    const onSuccess = () => {
      navigate('/login');
      window.location.reload();
    };
    dispatch(logout(onSuccess));
  };

  const open = Boolean(anchorEl);
  const id = open ? 'profile-popover' : undefined;
  const checkRole = () => {
    const roles = JSON.parse(sessionStorage.getItem('roles'));
    if (roles && roles.length) {
      if (roles.includes('SALES_ADMIN')) return DEFAULT_LANDING_ROUTE_ADMIN;
      return DEFAULT_LANDING_ROUTE_SALES;
    }
    return DEFAULT_LANDING_ROUTE;
  };
  return (
    <Wrapper>
      <Container>
        <div className="flex-center-center">
          <CustomLink to={checkRole()}>
            <Avatar hoverable size="sm" src={logo} />
          </CustomLink>
          <Typography color="white" variant="h5">
            Sales Report
          </Typography>
        </div>
        {isPublicRoute && (
          <Profile onClick={handleClick}>
            <Avatar hoverable size="sm" src={profilePicture} />
          </Profile>
        )}
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <ButtonBase onClick={handleLogout}>
            <MenuItem>Log Out</MenuItem>
          </ButtonBase>
        </Popover>
      </Container>
    </Wrapper>
  );
}

Header.propTypes = {
  /**
   * Application logo can be passed
   */
  logo: PropTypes.string,
  /**
   * Logged in user profile picture for avatar
   */
  profilePicture: PropTypes.string,
  /**
   * To show only logo on navbar when screen is in login/signup screen
   */
  isPublicRoute: PropTypes.bool,
};

Header.defaultProps = {
  logo: '',
  profilePicture: '',
  isPublicRoute: false,
};
