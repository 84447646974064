import React from 'react';

const defaultHeaderConfig = {
  logo: false,
  home: false,
  title: '',
};
export const privateRoutes = [
  {
    Path: '/upload-report',
    Component: React.lazy(() => import('../container/UploadReport')),
    meta: {
      name: 'upload-report',
    },
    headerConfig: {
      ...defaultHeaderConfig,
      home: false,
      logo: true,
    },
    id: 'upload-report',
  },
  {
    Path: '/sales-dashboard',
    Component: React.lazy(() => import('../container/SalesDashboard')),
    meta: {
      name: 'sales-dashboard',
    },
    headerConfig: {
      ...defaultHeaderConfig,
      home: false,
      logo: true,
    },
    id: 'sales-dashboard',
  },
  {
    Path: '/store-detail/:id',
    Component: React.lazy(() => import('../container/StoreDetails')),
    meta: {
      name: 'store-detail',
    },
    headerConfig: {
      ...defaultHeaderConfig,
      home: false,
      logo: true,
    },
    id: 'store-detail',
  },
];

export const publicRoutes = [
  {
    Path: '/login',
    Component: React.lazy(() => import('../container/Login')),
    meta: {
      name: 'Report login page',
    },
    headerConfig: {
      ...defaultHeaderConfig,
      home: false,
      logo: true,
    },
    id: 'login',
  },
  {
    Path: '/policies-and-conditions/:title',
    Component: React.lazy(() => import('../container/PoliciesAndConditions')),
    meta: {
      name: 'TnC page',
    },
    headerConfig: {
      ...defaultHeaderConfig,
      home: false,
      logo: false,
    },
    id: 'tnc',
  },
];

export default { privateRoutes, publicRoutes };
