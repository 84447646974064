import styled from '@emotion/styled';

const Body = styled('div')`
  display: flex;
  width: 100%;
  flex-direction: row;
  margin-top: 72px;
  justify-content: center;
`;
const Children = styled('div')`
  width: 100%;
`;
const LayoutComponent = {
  Body,
  Children,
};

export default LayoutComponent;
