import moment from 'moment';
import ReactGA from 'react-ga4';
import Store from '../redux/store';
import { setRequestStatus } from '../redux/slices/generalSlice';
import { regexSet } from './utils';
import { ERROR_CODES } from './constant';

export const setCookie = (cname, cvalue, exdays) => {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${cname}=${cvalue};${expires};path=/`;
};

export const getCookie = (cname) => {
  const name = `${cname}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

export const deleteAllCookies = () => {
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
  }
};

export const validateRegex = (text, pattern) => {
  let result = true;
  const re = pattern;
  result = re.test(text);
  return result;
};

export const validatePhoneNumber = (phoneNumber) =>
  validateRegex(phoneNumber, regexSet.Phone_Regex);

export const validateNumber = (number) =>
  validateRegex(number, regexSet.Number_Regex);

export const vaildateSpecialChar = (text) =>
  validateRegex(text, regexSet.SpecialChar_Regex);

export const textWithComma = (string) => {
  if (string) {
    return `${string}, `;
  }
  return string;
};

export const base64ToPdf = (data, fileName) => {
  const linkSource = `data:application/pdf;base64,${data}`;
  const downloadLink = document.createElement('a');
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
  downloadLink.remove();
};

export const resolveAndDownloadBlob = (data, name) => {
  let filename = name;
  filename = decodeURI(filename);
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  window.URL.revokeObjectURL(url);
  link.remove();
};

export const DispatchNotification = (message) => {
  Store.dispatch(
    setRequestStatus({
      description: message,
      header: 'Error',
      error: true,
      show: true,
    }),
  );
};
export const successMessage = (message) => ({
  error: false,
  show: true,
  header: 'Success',
  description: message,
});

export const nextDate = (date) => {
  return date.getHours() > 13
    ? new Date(date.getTime() + 24 * 60 * 60 * 1000)
    : date;
};

export const apiErrorResponse = (res = {}) => {
  if (
    ERROR_CODES.REGULAR_ERRORS.includes(res?.status) &&
    res?.data?.data?.errorMessage
  ) {
    return res?.data?.data?.errorMessage ?? 'User not registered';
  }

  if (
    ERROR_CODES.VALIDATION_ERRORS.includes(res?.status) &&
    res?.data?.data?.length
  ) {
    return res?.data?.data[0]?.messageError ?? 'Invalid Mobile Number';
  }

  return null;
};

export const scrollToId = (id, offset = 0) => {
  const element = document.getElementById(id);
  const elementPosition = element?.getBoundingClientRect()?.top;
  const offsetPosition = elementPosition + window.pageYOffset - offset;
  window.scrollTo({ top: offsetPosition, behavior: 'smooth' });
};

export const uniqueId = (i) =>
  (Math.random() + 1).toString(36).substring(7) + i;

export const compareJson = (object1, object2) => {
  return JSON.stringify(object1) === JSON.stringify(object2);
};

export const numToString = (num) => {
  return num?.toLocaleString();
};

export function deBounce(func, delay) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
}

export const dateDDMMYYYY = (date) => {
  return moment(date).format('DD/MM/YYYY');
};

export const time12hrFormat = (milliseconds) => {
  return moment(milliseconds).format('h:mm A');
};

export const capitalize = (string) => {
  if (string)
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  return string;
};

export const getPageTitle = () => {
  const pathArray = window.location.pathname.split('/');
  const name = pathArray[1];

  return capitalize(name);
};

export const triggerGAEvent = (
  action = '',
  category = '',
  label = '',
  value = '',
) => {
  ReactGA.event({ action, category, label, value });
};

export default {
  successMessage,
  setCookie,
  getCookie,
  deleteAllCookies,
  validatePhoneNumber,
  validateNumber,
  textWithComma,
  base64ToPdf,
  resolveAndDownloadBlob,
  DispatchNotification,
  nextDate,
  apiErrorResponse,
  scrollToId,
  uniqueId,
  compareJson,
  deBounce,
  dateDDMMYYYY,
  time12hrFormat,
  capitalize,
};
