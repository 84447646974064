import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { getCookie } from '../helpers/general';
/*eslint-disable */
export const LoginRoute = () => {
  const userAuth = getCookie('userAuth');
  const roles = JSON.parse(sessionStorage.getItem('roles'));
  return !userAuth ? (
    <Outlet />
  ) : (
    <Navigate
      to={
        roles && roles.length
          ? roles.includes('SALES_ADMIN')
            ? '/upload-report'
            : '/sales-dashboard'
          : '/'
      }
    />
  );
};

export const ProtectedRoute = () => {
  const userAuth = getCookie('userAuth');
  return userAuth ? <Outlet /> : <Navigate to={'/login'} />;
};

export default { LoginRoute, ProtectedRoute };
/*eslint-disable */
