import React, { Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Loader from '../component/Loader';
import { DEFAULT_LANDING_ROUTE } from '../helpers/constant';
import { uniqueId } from '../helpers/general';
import { LoginRoute, ProtectedRoute } from './routeController';
import { privateRoutes, publicRoutes } from './routes';

export default function Routers() {
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route element={<ProtectedRoute />}>
          {privateRoutes.map((Link) => {
            return (
              <Route
                exact
                path={Link.Path}
                key={uniqueId()}
                element={<Link.Component />}
              />
            );
          })}
        </Route>

        <Route element={<LoginRoute />}>
          {publicRoutes.map((Link) => {
            return (
              <Route
                exact
                path={Link.Path}
                key={uniqueId()}
                element={<Link.Component />}
              />
            );
          })}
        </Route>
        <Route path="*" element={<Navigate to={DEFAULT_LANDING_ROUTE} />} />
      </Routes>
    </Suspense>
  );
}
