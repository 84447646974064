import React from 'react';
import PropTypes from 'prop-types';
import Header from './header/Header';
import LayoutComponent from './layout.style';

export default function Layout({ children, ...rest }) {
  const { Body, Children } = LayoutComponent;

  return (
    <>
      <Header {...rest} />
      <Body>
        <Children>{children}</Children>
      </Body>
    </>
  );
}

Layout.propTypes = {
  /**
   * Wrapped JSX element will be displayed
   */
  children: PropTypes.node,
};

Layout.defaultProps = {
  children: {},
};
